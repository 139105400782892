(function () {
  'use strict';

  class Signup {
    constructor($mdDialog, $document) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.body = $document[0].body;
    }

    show(ev) {
      const vm = this;
      vm.$mdDialog.show({
        controller: 'SignUpCtrl',
        controllerAs: 'signup',
        templateUrl: 'components/signup/signup.tpl.html',
        parent: angular.element(vm.body),
        targetEvent: ev,
        clickOutsideToClose: true
      });
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Login
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Signup', Signup);
}());
